import React, { useEffect, useRef } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet';

const AboutUs = () => {

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);


  const wave = useRef(null);

  useEffect(() => {
    const image = document.querySelector(".waveline");
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          image.classList.add("active");
        } else {
          image.classList.remove("active");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (wave.current) {
      observer.observe(wave.current);
    }
    return () => {
      if (wave.current) {
        observer.unobserve(wave.current);
      }
    };
  }, [wave]);

  const wave2 = useRef(null);

  useEffect(() => {
    const image = document.querySelector(".waveline2");
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          image.classList.add("active2");
        } else {
          image.classList.remove("active2");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (wave2.current) {
      observer.observe(wave2.current);
    }
    return () => {
      if (wave2.current) {
        observer.unobserve(wave2.current);
      }
    };
  }, [wave2]);

  return (
    <div style={{overflowX:'hidden'}}> 
     <Helmet> 
            <meta charset="utf-8" /> 
            <title>About The Chiku Orchard: Unveiling Our Commitment to Unforgettable Experiences in Anand, Gujarat</title>
            <meta name="description" content="Welcome to The Chiku Orchard, where every moment is a celebration and every event is an unforgettable experience. Nestled in the heart of Anand, Gujarat, our commitment to excellence is reflected in every detail of our hospitality offerings. As a premier event venue, we take pride in our meticulously designed Party Lawns, Banquet Halls, and Accommodation options, creating the perfect canvas for your celebrations."/>
            <meta name="keywords" content="Anand Gujarat hospitality, Event venue commitment, Luxury accommodations Anand"/>
        </Helmet>
         <div class='d-flex justify-content-center align-items-center' style={{height:'100vh',backgroundImage:`url(${require('./images/aboutTopSlider.jpg')})`,backgroundSize:'cover',objectFit:'cover',backgroundRepeat:'no-repeat'}}>
          <div> 
            <p class="display-1 playfair  titleSize text-white" data-aos="zoom-in"   > 
              Who we are?
            </p>
            <p class='fs-5 text-white  col-lg-6 col-md-8 col-11 tJustify  m-auto mt-5 ' data-aos="fade-up"> Welcome to Chiku Orchard, where timeless beauty meets modern elegance. With 19 years of experience in wedding event management, we pride ourselves on creating unforgettable experiences for our guests</p>
          </div>
         </div>
         <div class='row' style={{background:'#fff',overflow:'hidden'}}>
      
            <div class='col-md-6 col-12 my-5'>
           
              <div class='col-11 m-auto'style={{position:'relative',left:'0%',top:'50px'}}   >
                <div class='col-md-9 m-auto' data-aos="fade-down"> 
                    <img src={require('./images/aboutP1.jpg')} style={{width:'100%',margin:'auto'}} alt="" />
                </div>
                <div class='col-md-6 col-9 m-auto' style={{position:'relative',left:'-10%',top:'-50px'}} data-aos="fade-down"> 
                    <img src={require('./images/aboutPp2.png')} style={{width:'100%',margin:'auto'}} alt="" />
                </div> 
                <br /> 
              </div> 
            </div>

            <div class='col-md-6 col-12 d-grid align-items-center'>
              <div class='col-11 m-auto'  >
                 <p class='fs-3 fw-semibold text-start' data-aos="fade-up">Harmony in Nature, Unmatched Celebrations </p>
                 <div class='d-flex justify-content-end my-5' data-aos="fade-up">
                  <p class='fs-5 col-lg-8   tJustify'>Nestled amidst the breath taking natural beauty, our 100,000 square feet property boasts a mesmerizing landscape adorned with majestic Chiku trees. These trees, with a legacy of 25 years, provide a serene and picturesque backdrop for your special day. Our commitment to preserving nature's beauty led us to design our building without compromising a single tree, allowing you to immerse yourself in the harmony of nature.</p>
                  
                 </div>
                 <br />
              </div> 
            </div>
         </div>

         {/* <div class='row m-0 p-0' >
              <div class='col-md-6 col-12 m-0 p-0' >
                <img src={require('./images/aboutP2.jpg')} style={{width:'100%',height:'100%',objectFit:'cover'}} alt="" />
              </div>
              <div class='col-md-6 col-12 p-0 m-0'   style={{backgroundImage:`url(${require('./images/aboutP3.jpg')})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',objectFit:'cover'}}>
              <div class="mt-4" ref={wave} style={{overflow:'hidden',position:'relative'}}> 
                <div style={{width:'fit-content',margin:'auto'}}> 
                  <div style={{ position: "relative" }}  > 
                      <div class="playfair text-center py-3 titleSize text-white  "> 
                          <div>Mision</div>  
                      </div> 
                    <div style={{ position: "absolute", top: "0",}} class="waveline" >
                      <div class="playfair text-center py-3 titleSize d-sm-flex gap-sm-4 gap-0 ">
                        
                         
                          <div style={{position:'relative' ,color:'white'}} >
                           Mision 
                              <svg style={{position:'absolute',bottom:'0',left:'10'}} xmlns="http://www.w3.org/2000/svg" width="100%" height="18" viewBox="0 0 548 18" fill="none">
                                <path d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16" stroke="white" stroke-width="3" stroke-linecap="round"/>
                              </svg> 
                          </div>  
                      </div>
                    </div> 
                </div>
            </div>
            <p class="fs-4  col-md-8 col-11 m-auto my-3 tJustify text-white"  data-aos="fade-up">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
              minim veniam, quis nostrud exercitation ullamco laboris nisi
            </p>
            <br />
         
          </div> 
              </div>

         </div> */}
 

         {/* <div class='row m-0 p-0' >
         <div class='col-md-6 col-12 p-0 m-0'   style={{backgroundImage:`url(${require('./images/aboutP4.jpg')})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%',objectFit:'cover'}}>
              <div class="mt-4" ref={wave2} style={{overflow:'hidden',position:'relative'}}> 
                <div style={{width:'fit-content',margin:'auto'}}> 
                  <div style={{ position: "relative" }}  > 
                      <div class="playfair text-center py-3 titleSize text-white  "> 
                          <div>Vision</div>  
                      </div> 
                    <div style={{ position: "absolute", top: "0",}} class="waveline2" >
                      <div class="playfair text-center py-3 titleSize d-sm-flex gap-sm-4 gap-0 ">
                          <div style={{position:'relative' ,color:'white'}} >
                           Vision 
                              <svg style={{position:'absolute',bottom:'0',left:'10'}} xmlns="http://www.w3.org/2000/svg" width="100%" height="18" viewBox="0 0 548 18" fill="none">
                                <path d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16" stroke="white" stroke-width="3" stroke-linecap="round"/>
                              </svg> 
                          </div>  
                      </div>
                    </div> 
                </div>
            </div>
            <p class="fs-4  col-md-8 col-11 m-auto my-3 tJustify text-white"  data-aos="fade-up">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, 
            </p>
            <br />
         
          </div> 
              </div>
              <div class='col-md-6 col-12 m-0 p-0' >
                <img src={require('./images/aboutP5.jpg')} style={{width:'100%',height:'100%',objectFit:'cover'}} alt="" />
              </div>
            

         </div> */}
    </div>
  )
}

export default AboutUs