import React, { useEffect, useState } from 'react'
import { RightSvgBlack } from './RightSvg'
import AOS from "aos";
import "aos/dist/aos.css";
import { MdEmail } from 'react-icons/md';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaTwitter } from 'react-icons/fa';
import { FaLocationPin } from 'react-icons/fa6';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';


const ContactUs = () => {

    const [contact, setContact] = useState({})

    const contactInfo = (e)=>{
        const {name , value} = e.target
        setContact({...contact, [name]: value})
    }
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);  
       const submitContact =()=>{
        if(
            contact?.name && contact?.name != '' &&
            contact?.email && contact?.email != '' &&
            contact?.phone && contact?.phone != '' &&
            contact?.message && contact?.message != ''
        ){
            axios.post('https://hi-labsolution.net/api/chiku-contact.php', contact)
            .then((res)=>{
                Swal.fire({
                    title: "",
                    text: res.data.responseMessage,
                    icon: "success"
                  });
                  setContact({
                    name: "",
                    email: "",
                    phone: "",
                    message: "", 
                  });
            })
            .catch((err)=>{
               
            }) 
        }else{
            Swal.fire({
                title: " ",
                text: "All fields must be required!",
                icon: "error"
              });
        }
       }
  return (
    <div>
        <div style={{background:'#F3F9F5'}}>
        <Helmet> 
            <meta charset="utf-8" /> 
            <title>Contact The Chiku Orchard - Premier Event Venue in Anand, Gujarat</title>
            <meta name="description" content="Anand Gujarat event venue contact, Party Lawns Anand booking, Banquet Halls Gujarat inquiries"/>
            <meta name="keywords" content="Connect with The Chiku Orchard, the premier event venue in Anand, Gujarat. Whether you're planning a grand celebration in our Party Lawns, organizing a corporate event in our Banquet Halls, or seeking information on comfortable Accommodation options, our dedicated team is here to assist you. Contact us for inquiries, bookings, and personalized assistance to ensure your event at The Chiku Orchard is seamless and memorable. Reach out to us today and let us help you create unforgettable moments in the heart of Anand, Gujarat."/>
        </Helmet>
            <div style={{height:'100px'}}></div>
            <div class='row'>
            <div class='col-md-7 col-11 m-auto text-start'>
                <p class='display-1 playfair titleSize text-start' style={{color:'#0C4027'}} data-aos="zoom-in">Contact Us</p>
                {/* <p class='fs-5' style={{color:'#878682'}}>Lorem ipsum dolor sit amet, <br />consectetur adipiscing elit, sed do</p> */}
                <div>
                    <input class='form-control rounded-5 p-3 px-4 mt-4 my-2' style={{background:"transparent",border:'1px solid'}} type="text" placeholder='Full Name' value={contact?.name} name='name' onChange={contactInfo} />
                    <div class='row'>  
                        <div class='col-md-6 col-12'>
                        <input class='form-control rounded-5 p-3 px-4 my-2 ' style={{background:"transparent",border:'1px solid'}} type="text" placeholder='Phone Number' value={contact?.phone} name="phone"  onChange={contactInfo}/>
                        </div>
                        <div class='col-md-6 col-12'>
                        <input class='form-control rounded-5 p-3 px-4 my-2  ' style={{background:"transparent",border:'1px solid'}} type="text" placeholder='Email' value={contact?.email} name='email'  onChange={contactInfo} />
                        </div>
                    </div>
                    <textarea class='form-control rounded-5 p-3 px-4 my-2' style={{background:"transparent",border:'1px solid'}} type="text" placeholder='Your Message' value={contact?.message} name='message'  onChange={contactInfo} />
                    <div class=''   >
                    <button class='btn button my-4'  onClick={submitContact}   >
                        <span class='d-flex align-items-center gap-2'>
                            <span class='fs-5'>Submit</span> <span class='buttonSpan'></span><RightSvgBlack/>
                        </span>
                    </button> 
                    </div>
                </div>
            </div>
                <div class='col-md-4 col-11 m-auto text-start d-flex align-items-center  '>
                    <div> 
                        <div class='my-4' style={{color:'#0C4027'}} >
                            <p class=''> <span class='fs-5'><FaPhoneAlt/></span> Email</p>
                            <p class='' style={{color:'#90A29B'}}>+91 79905 56971</p>
                        </div>
                        <div class='my-4' style={{color:'#0C4027'}} >
                            <p class=''> <span class='fs-5'><MdEmail/></span> Email</p>
                            <p class='' style={{color:'#90A29B'}}>info@thechiku.in</p>
                        </div>
                        <div class='d-flex gap-2 align-items-center my-4'>
                            <a href='https://www.instagram.com/thechikuorchard/' target='_blank' class='d-flex justify-content-center align-items-center' style={{width:'30px',height:'30px',border:'1px solid',borderRadius:'50%',color:'#0C4027',cursor:'pointer'}}>
                                <FaInstagram/>
                            </a>
                            {/* <a class='d-flex justify-content-center align-items-center' style={{width:'30px',height:'30px',border:'1px solid',borderRadius:'50%',color:'#0C4027',cursor:'pointer'}}>
                                <FaTwitter/>
                            </a> */}
                            <a href='https://www.facebook.com/thechikuorchard' target='_blank' class='d-flex justify-content-center align-items-center' style={{width:'30px',height:'30px',border:'1px solid',borderRadius:'50%',color:'#0C4027',cursor:'pointer'}}>
                                <FaFacebookF/>
                            </a>
                            {/* <a class='d-flex justify-content-center align-items-center' style={{width:'30px',height:'30px',border:'1px solid',borderRadius:'50%',color:'#0C4027',cursor:'pointer'}}>
                                <FaLinkedinIn/>
                            </a> */}
                        
                        </div>
                    </div>
                    
                </div>


            </div>

            <div class='' style={{background:'#0C4027',color:'white',borderRadius:'50px 50px 0 0'}}>
                <br /><br />
                <div class='row col-11 m-auto gap-md-0 gap-4'> 
                    <div class='col-md-3 col-11 m-auto text-start '>
                        <div>  
                        <p class='fs-1 playfair '> Visit Us</p>
                        <p><span><FaLocationPin/></span> Address</p>
                        <p class='my-2' style={{color:'#90A29B'}}>Zydus Rd, B/h, Agriculture Farm, Anand, Gujarat 388001</p>
                        <a class='mt-3' target='_blank' href='https://maps.google.com/maps?ll=22.569214,72.946689&z=14&t=m&hl=en&gl=IN&mapclient=embed&cid=17582573291113474075' style={{color:'#90A29B'}}>Get Directions <i class="bi bi-arrow-right-short"></i> </a>

                        </div>
                    </div>
                    <div class='col-md-9 col-11 m-auto '>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14737.95809909665!2d72.94017658641515!3d22.560787487734963!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e4fa32600fd15%3A0xf401d9407616e81b!2sThe%20Chiku%20Orchard!5e0!3m2!1sen!2sin!4v1706962740852!5m2!1sen!2sin" width={'100%'} height="400" style={{border:0,borderRadius:'50px',width:'100%'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                <br /><br />


            </div>
        </div>
    </div>
  )
}

export default ContactUs