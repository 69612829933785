import React, { useState } from 'react'
import { RightSvgBlack } from './RightSvg'
import axios from 'axios'
import Swal from 'sweetalert2'

const Form = ({form}) => {

    
    const [contact, setContact] = useState({})

    const contactInfo = (e)=>{
        const {name , value} = e.target
        setContact({...contact, [name]: value})
    }

 
    
       const submitContact =()=>{
        if(
            contact?.name && contact?.name != '' &&
            contact?.email && contact?.email != '' &&
            contact?.phone && contact?.phone != '' &&
            contact?.message && contact?.message != ''
        ){
            axios.post('https://hi-labsolution.net/api/chiku-contact.php', contact)
            .then((res)=>{
                Swal.fire({
                    title: "",
                    text: res.data.responseMessage,
                    icon: "success"
                  });
                  setContact({
                    name: "",
                    email: "",
                    phone: "",
                    message: "", 
                  });
                  form(false)
            })
            .catch((err)=>{
               
            }) 
        }else{
            Swal.fire({
                title: " ",
                text: "All fields must be required!",
                icon: "error"
              });
        }
       }
  return (
    <div style={{background:'white'}}> 
    <br /> 
        <div class='d-flex align-items-center justify-content-center' style={{height:'100vh',width:'100%',background: 'linear-gradient(180deg, rgba(254, 251, 228, 0.00) 0%, rgba(254, 251, 227, 0.69) 100%)',overflowY:'auto'}}>
            <div class='col-md-8 col-11 m-auto'>
                <p class='display-1 playfair titleSize text-start' style={{color:'#0C4027'}} data-aos="zoom-in">Fill the form</p>
                {/* <p class='fs-5' style={{color:'878682'}}>Lorem ipsum dolor sit amet, consectetur <br />adipiscing elit, sed do </p> */}
                <div>
                    <input class='form-control rounded-5 p-3 px-4 mt-5 my-2' style={{background:"transparent",border:'1px solid'}} type="text" placeholder='Full Name' value={contact?.name} name='name' onChange={contactInfo} />
                    <div class='row'>  
                        <div class='col-md-6 col-12'>
                        <input class='form-control rounded-5 p-3 px-4 my-2 ' style={{background:"transparent",border:'1px solid'}} type="text" placeholder='Phone Number' value={contact?.phone} name='phone' onChange={contactInfo} />
                        </div>
                        <div class='col-md-6 col-12'>
                        <input class='form-control rounded-5 p-3 px-4 my-2  ' style={{background:"transparent",border:'1px solid'}} type="text" placeholder='Email' value={contact?.email} name='email' onChange={contactInfo} />
                        </div>
                    </div>
                    <textarea class='form-control rounded-5 p-3 px-4 my-2' style={{background:"transparent",border:'1px solid'}} type="text" placeholder='Your Message' value={contact?.message} name='message' onChange={contactInfo} />
                    <div class='d-flex gap-3 align-items-center justify-content-end  '  >
                    <button class='btn button my-4' onClick={()=>form(false)}>
                        <span class='d-flex align-items-center gap-2'>
                            <span class='fs-5'>Close</span> <span class='buttonSpan'></span><RightSvgBlack/>
                        </span>
                    </button>
                    <button class='btn button my-4' onClick={submitContact}>
                        <span class='d-flex align-items-center gap-2'>
                            <span class='fs-5'>Submit</span> <span class='buttonSpan'></span><RightSvgBlack/>
                        </span>
                    </button>

                    </div>
                </div>
            </div>

        </div>
        <br /><br />
    </div>
  )
}

export default Form