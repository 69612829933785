import React, { useEffect, useRef } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from 'react-router-dom';

const OurAmenities = () => {

    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

      const navigate = useNavigate()

    const wave = useRef(null);

    useEffect(() => {
      const image = document.querySelector(".waveline");
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            image.classList.add("active");
          } else {
            image.classList.remove("active");
          }
        },
        { rootMargin: "0px", threshold: 0.5 }
      );
      if (wave.current) {
        observer.observe(wave.current);
      }
      return () => {
        if (wave.current) {
          observer.unobserve(wave.current);
        }
      };
    }, [wave]);

    const about1 = useRef(null);

    useEffect(() => {
      const bg = document.querySelector(".about1");
      bg.style.clipPath = 'inset(20% 50% 20% 50% round 120px)'
      bg.style.transition ="1.5s"
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            bg.style.clipPath = 'inset(20% 10% 20% 10% round 120px)'
          } else {
            bg.style.clipPath = 'inset(20% 50% 20% 50% round 120px)' 
          }
        },
        { rootMargin: "0px", threshold: 0.5 }
      );
      if (about1.current) {
        observer.observe(about1.current);
      }
      return () => {
        if (about1.current) {
          observer.unobserve(about1.current);
        }
      };
    }, [about1]);

    const about2 = useRef(null);

    useEffect(() => {
      const bg = document.querySelector(".about2");
      bg.style.clipPath = 'inset(100% 0 0 0 round 50% 50% 0 0)'
      bg.style.transition ="1.5s"
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            bg.style.clipPath = 'inset(0 0 0 0 round 50% 50% 0 0)'
          } else {
            bg.style.clipPath = 'inset(100% 0 0 0 round 50% 50% 0 0)' 
          }
        },
        { rootMargin: "0px", threshold: 0.5 }
      );
      if (about2.current) {
        observer.observe(about2.current);
      }
      return () => {
        if (about2.current) {
          observer.unobserve(about2.current);
        }
      };
    }, [about2]);

    const about3 = useRef(null);

    useEffect(() => {
      const bg = document.querySelector(".about3");
      bg.style.clipPath = 'inset(0 100% 0 0 round 0 50% 50% 0)'
      bg.style.transition ="1.5s"
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            bg.style.clipPath = 'inset(0 12% 0 0 round 0 50% 50% 0)'
          } else {
            bg.style.clipPath = 'inset(0 100% 0 0 round 0 50% 50% 0)' 
          }
        },
        { rootMargin: "0px", threshold: 0.5 }
      );
      if (about3.current) {
        observer.observe(about3.current);
      }
      return () => {
        if (about3.current) {
          observer.unobserve(about3.current);
        }
      };
    }, [about3]);

    const about4 = useRef(null);

    useEffect(() => {
        const bg = document.querySelector(".about4");
        bg.style.clipPath = 'inset(100% 0 0 0 round 50% 50% 0 0)'
        bg.style.transition ="1.5s"
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
                bg.style.clipPath = 'inset(0 0 0 0 round 50% 50% 0 0)' 
            } else {
                bg.style.clipPath = 'inset(100% 0 0 0 round 50% 50% 0 0)' 
            }
          },
          { rootMargin: "0px", threshold: 0.5 }
        );
        if (about4.current) {
          observer.observe(about4.current);
        }
        return () => {
          if (about4.current) {
            observer.unobserve(about4.current);
          }
        };
      }, [about4]);

      const about5 = useRef(null);

    useEffect(() => {
        const bg = document.querySelector(".about5"); 
        bg.style.clipPath = 'inset(0 0 100% 0 round 0 0 50% 50%)' 
        bg.style.transition ="1.5s"
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              
              bg.style.clipPath = 'inset(0 0 40% 0 round 0 0 50% 50%)'

            } else {
                bg.style.clipPath = 'inset(0 0 100% 0 round 0 0 50% 50%)'  
            }
          },
          { rootMargin: "0px", threshold: 0.5 }
        );
        if (about5.current) {
          observer.observe(about5.current);
        }
        return () => {
          if (about5.current) {
            observer.unobserve(about5.current);
          }
        };
      }, [about5]);

 
  return (
    <div>
        <div class='col-11 m-auto'>
            <div class='mt-5'>
                <br />
                <p class='display-1 playfair titleSize text-start' style={{color:'#0C4027'}} data-aos="zoom-in">Our <br /> Amenitie</p>
            </div>
            <div class='d-flex justify-content-end'>
                <div class='col-md-5 col-11 m-md-0 m-auto my-4'>
                    <p class='fs-4 tJustify ' data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                </div> 
            </div> 
        </div>

        <div class='col-11 m-auto my-5'>
            <div class='row'>
                <div class='col-md-6 col-12 py-3 '>
                    <div style={{background:'#0C4027',height:'350px',width:'100%',borderRadius:"25px",cursor:'pointer'}} ref={about1} onClick={()=>navigate('/loan-garden')} >
                        <div class='d-flex align-items-center justify-content-center about1 ' style={{backgroundImage:`url(${require('./images/hotel2.jpg')})`,backgroundSize:'100% 100%',width:'100%',height:'100%',objectFit:'cover'}}>
                            <p class='display-4 playfair  'style={{ color:'#7BFF5A'}}>Loan Garden</p>
                        </div>
                    </div>
                </div>
                <div class='col-md-6 col-12 py-3'>
                    <div style={{background:'#73512C',height:'350px',width:'100%',borderRadius:"25px",overflowY:'hidden',cursor:'pointer'}} ref={about2} onClick={()=>navigate('/banquet-hall')}>
                        <br />
                         <p class='display-4 playfair 'style={{ color:'#ffffff'}}>Banquet Hall</p>
                    
                         <div class='about2' style={{backgroundImage:`url(${require('./images/hotel1.jpg')})`,backgroundSize:'100% 100%',width:'100%',height:'100%',objectFit:'cover'}}>
                        </div>
                    </div>
                </div>  
            </div>

            <div class='row justify-content-center'>
                <div class='col-lg-4 col-md-6 col-12 py-md-2 py-3 '>
                    <div style={{background:'#73512C',height:'350px',width:'100%',borderRadius:"25px",cursor:'pointer'}} ref={about3} onClick={()=>navigate('/family-rooms')}>
                        <div class='d-flex align-items-center justify-content-center about3 ' style={{backgroundImage:`url(${require('./images/hotel3.jpg')})`,backgroundSize:'100% 100%',width:'100%',height:'100%',objectFit:'cover'}}>
                            <p class='display-4 playfair  'style={{ color:'#FFFFFF',marginLeft:'-30%'}}>Family <br />Rooms</p>
                        </div>
                    </div>
                </div>
                <div class='col-lg-4 col-md-6 col-12 py-md-2 py-3'>
                    <div style={{background:'#0C4027',height:'350px',width:'100%',borderRadius:"25px",overflowY:'hidden',cursor:'pointer'}} ref={about4} onClick={()=>navigate('/suites-rooms')}>
                        <br />
                         <p class='display-4 playfair 'style={{ color:'#ffffff'}}>Suites Rooms</p>
                         
                         <div class='about4' style={{backgroundImage:`url(${require('./images/hotel4.jpg')})`,backgroundSize:'100% 100%',width:'100%',height:'100%',objectFit:'cover'}}>
                        </div>
                    </div>
                </div>  
                <div class='col-lg-4 col-md-6 col-12 py-md-2 py-3'>
                    <div style={{background:'#6F9B64',height:'350px',width:'100%',borderRadius:"25px",overflowY:'hidden',position:'relative',cursor:'pointer'}} ref={about5} onClick={()=>navigate('/honeymoon-rooms')}>
                        <div class='d-flex justify-content-center' style={{ color:'#ffffff',position:'absolute',bottom:'5%',width:'100%'}}> 
                            <p class='display-4 playfair  m-auto '>Honeymoon <br /> Rooms</p>
                        </div>
                         <div class='about5' style={{backgroundImage:`url(${require('./images/hotel5.jpg')})`,backgroundSize:'100% 100%',width:'100%',height:'100%',objectFit:'cover'}}>
                        </div>
                    </div>
                </div> 
            </div>

        </div>
        
    </div>
  )
}

export default OurAmenities