import React, { useEffect, useRef } from 'react'
import { BiLogoFacebookCircle, BiLogoInstagramAlt, BiLogoTwitter } from 'react-icons/bi';
import AOS from "aos";
import "aos/dist/aos.css";
import { RightSvgBlack } from './RightSvg';
import { useNavigate } from 'react-router-dom';

const Footer = ({form}) => {
    useEffect(() => {
        AOS.init({ duration: 1000 });
      }, []);

      const navigate = useNavigate()  
      const footerRef = useRef(null);

   
  return (
    <div ref={footerRef} style={{overflow:'hidden'}}>
          <div class='row gap-4 parallax'  style={{overflow:'hidden'}}>
        <div class='col-md-3'>
          <div>
            <img src={require('./images/tree.png')} style={{width:'250px'}} alt="" />
          </div>
       
        </div>
        <div class='col-md-8 text-start d-grid align-items-center'> 
            <div class='col-11 m-auto '> 
                <p class='display-1 playfair '  data-aos="fade-up" style={{color:'#0C4027'}}>Call us for booking <br /> and questions </p>
                <div class='d-flex justify-content-center ' data-aos="fade-up">
                <button class='btn button my-4'  onClick={()=>form(true)}>
                  <span class='d-flex align-items-center gap-2'>
                    <span class='fs-5'>Connect Now</span> <span class='buttonSpan'></span><RightSvgBlack/>
                  </span>
                </button>
                </div>
            </div>
        </div>
      </div>
        <div class='footerSpace'></div>
        <div style={{borderRadius:'50px 50px 0 0 ',background:'#765738'}} data-aos="fade-up" > 
        <br /><br />
            <div class='col-11 m-auto row gap-md-0 gap-sm-4 gap-4'>
                <div class='col-md-3 col-sm-5 col-12 text-center'>
                    <img src={require('./images/logo.png')} style={{width:'120px'}} alt="" />
                </div>
                <div class='col-md-3 col-sm-5 col-12 text-start'>
                    <ul class='text-start p-0'>
                        <li class='mt-2' style={{color:'#C59C73'}}>Links</li>
                        <li class='mt-1' style={{color:'#FCEDD3',cursor:'pointer'}} onClick={()=>navigate('/')}>Home</li>
                        <li class='mt-1' style={{color:'#FCEDD3',cursor:'pointer'}} onClick={()=>navigate('/about-us')}>About Us</li>
                        <li class='mt-1' style={{color:'#FCEDD3',cursor:'pointer'}} onClick={()=>navigate('/lawn-garden')}>Lawn Garden</li>
                        <li class='mt-1' style={{color:'#FCEDD3',cursor:'pointer'}} onClick={()=>navigate('/banquet-hall')}>Banquet Hall</li>
                        <li class='mt-1' style={{color:'#FCEDD3',cursor:'pointer'}} onClick={()=>navigate('/contact-us')}>Contact Us</li> 
                    </ul>
                </div>
                <div class='col-md-3 col-sm-5 col-12 text-start'>
                    <ul class='text-start p-0'>
                        <li class='mt-2' style={{color:'#C59C73'}}>Contact Us</li>
                        <li class='mt-3' style={{color:'#FCEDD3'}}>Adress : Zydus Rd, B/h, Agriculture Farm, Anand, Gujarat 388001</li>
                        <li class='mt-1' style={{color:'#FCEDD3'}}>Phone : +91 79905 56971</li>
                        <li class='mt-2' style={{color:'#FCEDD3'}}>Mail : info@thechiku.in</li>
                    
                    </ul>
 
                </div>
                <div class='col-md-3 col-sm-5 col-12 text-start'>
                    <ul class='text-start p-0 '>
                        <li class='mt-1' style={{color:'#C59C73'}}>Follow us on</li>
                        <div class='d-flex fs-1 gap-3'> 
                            <a href='https://www.facebook.com/thechikuorchard' target='_blank' class='mt-1' style={{color:'#FCEDD3',cursor:'pointer'}}><BiLogoFacebookCircle/></a>
                            <a href='https://www.instagram.com/thechikuorchard' target='_blank' class='mt-1' style={{color:'#FCEDD3',cursor:'pointer'}}><BiLogoInstagramAlt/></a>
                            {/* <a href='' class='mt-1' style={{color:'#FCEDD3',cursor:'pointer'}}><BiLogoTwitter/></a> */}
                        </div> 
                    </ul>    
                </div> 
            </div>  
            <div class='mt-5 col-11 m-auto' style={{border:'1px solid #C59C73'}}></div>
            <div class='col-11 m-auto mt-4 flex-warp gap-md-0 gap-sm-0 gap-4  row'> 
                <div class='col-md-6 col-12 text-start' style={{color:'#C59C73'}}>© 2024 all right reserve The Chiku Orchard <br /> Developed by :  hi-lab solution <a href="https://hi-labsolution.com/" target="_blank"><img src="https://hi-labsolution.com/hlogo.png" alt="Hi-Lab Solution"/></a></div>
            </div>
            <br /> 
        </div>
    </div>
  )
}

export default Footer