import React, { useEffect, useRef, useState } from "react";
import { HomeTop } from "./MultiCarousel";
import "./home.css";
import "./style.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { RightSvgBlack, RightSvgWhite } from "./RightSvg";
import { useScroll, useTransform, motion } from "framer-motion";
import { Link } from "react-scroll";
import { Helmet } from "react-helmet";



const topSlider = [
  {
    img: `${require("./images/newTopSlider.jpg")}`,
    text: "Chiku Orchard",
  },
];

const topVideoSlider = [
  {
    img: `${require("./images/newTopSlider.jpg")}`,
    text: "Chiku Orchard",
  },
];

const Home = () => {
  const [scrollPosition, setScrollPosition] = useState();
  const [ourAmeFilter, setOurAmeFilter] = useState("text1");

  const { scrollYProgress } = useScroll();
  const x = useTransform(scrollYProgress, [0, 1], [0, -5000]);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const refHome1 = useRef(null);

  useEffect(() => {
    const images = document.querySelectorAll(".swing");
    images.forEach((img, i) => {
      img.style.opacity = "0";
      img.style.marginTop = "-300px";
      img.style.transition = "2s";
    });
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          images.forEach((img, i) => {
            img.style.marginTop = "0";
            img.style.opacity = "1";
            // setTimeout(() => {
            //   img.classList.add("swingCons");
            // }, 1500);
          });
        } else {
          images.forEach((img, i) => {
            img.style.opacity = "0";
            img.style.marginTop = "-300px";
          });
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (refHome1.current) {
      observer.observe(refHome1.current);
    }
    return () => {
      if (refHome1.current) {
        observer.unobserve(refHome1.current);
      }
    };
  }, [refHome1]);
  // ----------------------------------------------------------------
  const wave = useRef(null);

  useEffect(() => {
    const image = document.querySelector(".waveline");
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          image.classList.add("active");
        } else {
          image.classList.remove("active");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (wave.current) {
      observer.observe(wave.current);
    }
    return () => {
      if (wave.current) {
        observer.unobserve(wave.current);
      }
    };
  }, [wave]);

  // ----------------------------------------------------------------

  const amenities = useRef(null);
  useEffect(() => {
    const div = document.querySelectorAll(".ourAmeMain");
    const space = document.querySelector(".whyUsMainPcSpace");
    const spacemb = document.querySelector(".whyUsMainMbSpace");

    const div1 = document.querySelector(".whyUsCardPc1");
    const div2 = document.querySelector(".whyUsCardPc2");
    const div3 = document.querySelector(".whyUsCardPc3");
    const div4 = document.querySelector(".whyUsCardPc4");
    const div5 = document.querySelector(".whyUsCardMb1");
    const div6 = document.querySelector(".whyUsCardMb2");
    const div7 = document.querySelector(".whyUsCardMb3");
    const div8 = document.querySelector(".whyUsCardMb4");
    div.forEach((div, i) => {
      div.style.transform = "scale(0.90)";
    });
    const image = document.querySelectorAll(".ourAme");
    // image.forEach((img,i)=>{
    //     img.style.opacity='0'
    //     img.style.transition='.5s'
    // })
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          div.forEach((div, i) => {
            div.style.transform = "scale(1)";
          });
          space.style.height = "80vh";
          spacemb.style.height = "80vh";

          // setTimeout(()=>{
          //     image.forEach((img,i)=>{
          //         img.style.opacity='1'
          //         img.style.transition='1s'
          //     })
          // },500)
          div1.style.opacity = "0";
          div2.style.opacity = "0";
          div3.style.opacity = "0";
          div4.style.opacity = "0";
          div5.style.opacity = "0";
          div6.style.opacity = "0";
          div7.style.opacity = "0";
          div8.style.opacity = "0";

          div1.style.top = "-30%";
          div1.style.left = "-30%";
          div1.style.transform = "rotate(50.697deg)";
          div1.style.transition = "2s";

          div2.style.top = "-30%";
          div2.style.right = "-30%";
          div2.style.transform = "rotate(50.697deg)";
          div2.style.transition = "2s";

          div3.style.bottom = "-30%";
          div3.style.left = "-30%";
          div3.style.transform = "rotate(-50.697deg)";
          div3.style.transition = "2s";

          div4.style.bottom = "-30%";
          div4.style.right = "-30%";
          div4.style.transform = "rotate(50.697deg)";
          div4.style.transition = "2s";

          div5.style.top = "-30%";
          div5.style.left = "-30%";
          div5.style.transform = "rotate(50.697deg)";
          div5.style.transition = "2s";

          div6.style.top = "-30%";
          div6.style.right = "-30%";
          div6.style.transform = "rotate(50.697deg)";
          div6.style.transition = "2s";

          div7.style.bottom = "-30%";
          div7.style.left = "-30%";
          div7.style.transform = "rotate(-50.697deg)";
          div7.style.transition = "2s";

          div8.style.bottom = "-30%";
          div8.style.right = "-30%";
          div8.style.transform = "rotate(50.697deg)";
          div8.style.transition = "2s";
        } else {
          div.forEach((div, i) => {
            div.style.transform = "scale(0.95)";
          });
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (amenities.current) {
      observer.observe(amenities.current);
    }
    return () => {
      if (amenities.current) {
        observer.unobserve(amenities.current);
      }
    };
  }, [amenities]);

  // ----------------------------------------------------------------

  const whyUsDivPc = useRef(null);

  useEffect(() => {
    const background = document.querySelector(".whyUsPc");
    const space = document.querySelector(".whyUsMainPcSpace");
    // background.style.clipPath = "inset(80% 0 0 0 round 50px 50px 0px 0px)";
    space.style.height = "80vh";
    space.style.transition = "1.5s";

    background.style.transition = "1s";

    const div1 = document.querySelector(".whyUsCardPc1");
    const div2 = document.querySelector(".whyUsCardPc2");
    const div3 = document.querySelector(".whyUsCardPc3");
    const div4 = document.querySelector(".whyUsCardPc4");

    div1.style.opacity = "0";
    div2.style.opacity = "0";
    div3.style.opacity = "0";
    div4.style.opacity = "0";

    div1.style.top = "-30%";
    div1.style.left = "-30%";
    div1.style.transform = "rotate(50.697deg)";
    div1.style.transition = "2s";

    div2.style.top = "-30%";
    div2.style.right = "-30%";
    div2.style.transform = "rotate(50.697deg)";
    div2.style.transition = "2s";

    div3.style.bottom = "-30%";
    div3.style.left = "-30%";
    div3.style.transform = "rotate(-50.697deg)";
    div3.style.transition = "2s";

    div4.style.bottom = "-30%";
    div4.style.right = "-30%";
    div4.style.transform = "rotate(50.697deg)";
    div4.style.transition = "2s";

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // background.style.clipPath = "inset(0% 0 0 0 round 50px 50px 0px 0px)";
          space.style.height = "0";
          div1.style.opacity = "1";
          div2.style.opacity = "1";
          div3.style.opacity = "1";
          div4.style.opacity = "1";

          setTimeout(() => {
            div1.style.top = "15%";
            div1.style.left = "15%";
            div1.style.transform = "rotate(-14.697deg)";
          }, 1000);
          setTimeout(() => {
            div2.style.top = "15%";
            div2.style.right = "30%";
            div2.style.transform = "rotate(-6.697deg)";
          }, 1500);
          setTimeout(() => {
            div3.style.bottom = "15%";
            div3.style.left = "30%";
            div3.style.transform = "rotate(10.697deg)";
          }, 2000);
          setTimeout(() => {
            div4.style.bottom = "15%";
            div4.style.right = "15%";
            div4.style.transform = "rotate(-7.697deg)";
          }, 2500);
        } else {
          // background.style.clipPath = "inset(80% 0 0 0 round 50px 50px 0px 0px)";
          // setTimeout(()=>{
          //   space.style.height='100vh'
          // },1000)
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (whyUsDivPc.current) {
      observer.observe(whyUsDivPc.current);
    }
    return () => {
      if (whyUsDivPc.current) {
        observer.unobserve(whyUsDivPc.current);
      }
    };
  }, [whyUsDivPc]);
  // ----------------------------------------------------------------

  const whyUsDivMb = useRef(null);

  useEffect(() => {
    const background = document.querySelector(".whyUsMb");
    const space = document.querySelector(".whyUsMainMbSpace");
    space.style.height = "80vh";
    space.style.transition = "1.5s";

    background.style.transition = "2s";

    const div1 = document.querySelector(".whyUsCardMb1");
    const div2 = document.querySelector(".whyUsCardMb2");
    const div3 = document.querySelector(".whyUsCardMb3");
    const div4 = document.querySelector(".whyUsCardMb4");

    div1.style.opacity = "0";
    div2.style.opacity = "0";
    div3.style.opacity = "0";
    div4.style.opacity = "0";

    div1.style.top = "-30%";
    div1.style.left = "-30%";
    div1.style.transform = "rotate(50.697deg)";
    div1.style.transition = "2s";

    div2.style.top = "-30%";
    div2.style.right = "-30%";
    div2.style.transform = "rotate(50.697deg)";
    div2.style.transition = "2s";

    div3.style.bottom = "-30%";
    div3.style.left = "-30%";
    div3.style.transform = "rotate(-50.697deg)";
    div3.style.transition = "2s";

    div4.style.bottom = "-30%";
    div4.style.right = "-30%";
    div4.style.transform = "rotate(50.697deg)";
    div4.style.transition = "2s";

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          space.style.height = "0";
          div1.style.opacity = "1";
          div2.style.opacity = "1";
          div3.style.opacity = "1";
          div4.style.opacity = "1";

          setTimeout(() => {
            div1.style.top = "5%";
            div1.style.left = "15%";
            div1.style.transform = "rotate(-14.697deg)";
          }, 1000);
          setTimeout(() => {
            div2.style.top = "30%";
            div2.style.right = "15%";
            div2.style.transform = "rotate(-6.697deg)";
          }, 1500);
          setTimeout(() => {
            div3.style.bottom = "20%";
            div3.style.left = "10%";
            div3.style.transform = "rotate(10.697deg)";
          }, 2000);
          setTimeout(() => {
            div4.style.bottom = "0%";
            div4.style.right = "15%";
            div4.style.transform = "rotate(-7.697deg)";
          }, 2500);
        } else {
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (whyUsDivMb.current) {
      observer.observe(whyUsDivMb.current);
    }
    return () => {
      if (whyUsDivMb.current) {
        observer.unobserve(whyUsDivMb.current);
      }
    };
  }, [whyUsDivMb]);



  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition((-window.scrollY % window.innerWidth) / 2);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (

    <div style={{ overflow: "hidden" }}>
      <Helmet>
        <meta charset="utf-8" />
        <title>Celebrate in Style at The Chiku Orchard: Your Premier Venue in Anand, Gujarat</title>
        <meta name="description" content="Welcome to The Chiku Orchard, your ultimate destination for unforgettable celebrations in Anand, Gujarat, India. Discover our exquisite Party Lawns, versatile Banquet Halls, and comfortable Accommodation options, all set amidst the picturesque surroundings of Anand. Whether you're planning a grand celebration, a corporate event, or a cozy gathering, our meticulously designed facilities cater to your every need. Join us at The Chiku Orchard and create lasting memories in an enchanting setting. Book your space now for an unparalleled experience of luxury and hospitality in the heart of Anand, Gujarat." />
        <meta name="keywords" content="Party Lawns, Banquet Halls, Event Venue, Accommodation, Anand Gujarat" />
      </Helmet>
      <div style={{ height: "100vh", position: "relative" }}>
        {/* <HomeTop data={topSlider} /> */}
        <div className='d-flex justify-content-center align-items-center' style={{ height: '100vh', width: '100%', overflow: 'hidden' }}>
          <video autoPlay muted loop playsInline preload="metadata" style={{ objectFit: 'cover', width: '100%', height: '100%' }}>
            <source src='chikuOutdoor.mp4' type="video/mp4" />
          </video>
        </div>



        <div
          style={{
            height: "",
            position: "absolute",
            bottom: "10%",
            right: "5%",
          }}
        >
          <div style={{ position: "relative" }}>
            <img
              src={require("./images/circleInner.png")}
              alt=""
              style={{ width: "150px", }}
            />
            <img
              class="downCircle"
              src={require("./images/circleOuter.png")}
              alt=""
              style={{
                width: "150px",
                position: "absolute",
                top: "-5px",
                left: "5px",
              }}
            />
          </div>
        </div>
        {/* <div
          style={{
            width: "115px",
            position: "absolute",
            bottom: "-35px",
            left: "15%",
          }}
        >
          <img
            src={require("./images/leaves1.png")}
            style={{ width: "100%" }}
            alt=""
          />
        </div>
        <div
          style={{
            width: "140px",
            position: "absolute",
            bottom: "-40px",
            right: "15%",
          }}
        >
          <img
            src={require("./images/leaves2.png")}
            style={{ width: "100%" }}
            alt=""
          />
        </div> */}
      </div>

      <div
        class="mt-4 chikuOrch"
        ref={wave}
        style={{ overflow: "hidden", position: "relative" }}
      >
        <div style={{ width: "fit-content", margin: "auto" }}>
          <div style={{ position: "relative" }} data-aos="zoom-in">
            <div
              class="playfair text-center py-3 titleSize d-sm-flex gap-sm-4 gap-0"
              style={{ color: "#0C4027" }}
            >
              <div>Chiku</div>
              <div>Orchard</div>
            </div>
            <div style={{ position: "absolute", top: "0" }} class="waveline">
              <div
                class="playfair text-center py-3 titleSize d-sm-flex gap-sm-4 gap-0 "
                style={{ color: "#0C4027" }}
              >
                <div>Chiku</div>
                <div style={{ position: "relative" }}>
                  Orchard
                  <svg
                    style={{ position: "absolute", bottom: "0", left: "10" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="18"
                    viewBox="0 0 548 18"
                    fill="none"
                  >
                    <path
                      d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                      stroke="#0C4027"
                      stroke-width="3"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          class="fs-5 col-lg-6 col-md-8 col-11 m-auto my-3 tJustify text-black"
          data-aos="fade-up"
        >
          Discover Chiku Orchard, where timeless beauty meets modern elegance.
          With 19 years of wedding event management experience, our 100,000 sq.
          ft. property boasts a stunning landscape of majestic Chiku trees.
          Enjoy a magical ambiance and immerse yourself in nature.
        </p>
        <div data-aos="fade-up">
          <button class="btn button">
            <span class="d-flex align-items-center gap-2">
              <span class="fs-5">Read More</span>{" "}
              <span class="buttonSpan">
                <RightSvgBlack />
              </span>
            </span>
          </button>
        </div>
      </div>

      <div
        class="homepMainPc mt-5"
        ref={refHome1}
        style={{ overflow: "hidden" }}
      >
        <div class="homep1 swing">
          <img
            src={require("./images/p1.png")}
            style={{ width: "100%", objectFit: "cover" }}
            alt=""
          />{" "}
        </div>
        <div class="homep2 swing">
          <img
            src={require("./images/p2.png")}
            style={{ width: "100%", objectFit: "cover" }}
            alt=""
          />{" "}
        </div>
        <div class="homep3 swing">
          <img
            src={require("./images/p3.png")}
            style={{ width: "100%", objectFit: "cover" }}
            alt=""
          />{" "}
        </div>
        <div class="homep4 swing">
          <img
            src={require("./images/p4.png")}
            style={{ width: "100%", objectFit: "cover" }}
            alt=""
          />{" "}
        </div>
        <div class="homep5 swing">
          <img
            src={require("./images/p5.png")}
            style={{ width: "100%", objectFit: "cover" }}
            alt=""
          />{" "}
        </div>
        <div class="homep6 swing">
          <img
            src={require("./images/p6.png")}
            style={{ width: "100%", objectFit: "cover" }}
            alt=""
          />{" "}
        </div>
        <div class="homep7 swing">
          <img
            src={require("./images/p7.png")}
            style={{ width: "100%", objectFit: "cover" }}
            alt=""
          />{" "}
        </div>
      </div>
      <div class="homepMainMb mb-5" style={{ overflow: "hidden" }}>
        <div>
          <img
            src={require("./images/p1.png")}
            style={{ width: "300px", objectFit: "cover" }}
            alt=""
            data-aos="fade-down"
          />{" "}
        </div>
        <div>
          <img
            src={require("./images/p2.png")}
            style={{ width: "330px", objectFit: "cover" }}
            alt=""
            data-aos="fade-down"
          />{" "}
        </div>
        <div>
          <img
            src={require("./images/p3.png")}
            style={{ width: "300px", objectFit: "cover" }}
            alt=""
            data-aos="fade-down"
          />{" "}
        </div>
        <div>
          <img
            src={require("./images/p4.png")}
            style={{ width: "250px", objectFit: "cover" }}
            alt=""
            data-aos="fade-down"
          />{" "}
        </div>
        <div>
          <img
            src={require("./images/p5.png")}
            style={{ width: "240px", objectFit: "cover" }}
            alt=""
            data-aos="fade-down"
          />{" "}
        </div>
        <div>
          <img
            src={require("./images/p6.png")}
            style={{ width: "220px", objectFit: "cover" }}
            alt=""
            data-aos="fade-down"
          />{" "}
        </div>
        <div>
          <img
            src={require("./images/p7.png")}
            style={{ width: "200px", objectFit: "cover" }}
            alt=""
            data-aos="fade-down"
          />{" "}
        </div>
      </div>



      <motion.div style={{ x }} class="d-flex justify-content-end">
        <p class="display-1 playfair  d-flex gap-sm-5 gap-1  titleSize ourAmenities">
          {" "}
          <span style={{ color: "#985819" }}>Our</span>{" "}
          <span style={{ color: "#0C4027" }}>Amenities</span>
        </p>
      </motion.div>


      <div class="mt-5" style={{ overflow: "hidden" }}>
        <div
          style={{ width: "95%", transition: "2s" }}
          class="row m-auto justify-content-end"
          ref={amenities}
        >
          <div class="col-lg-3 col-md-4 col-12 p-2">
            <div class="col-10 m-auto my-5">
              <p class="tJustify ourAmeText" data-aos="fade-up">
                {ourAmeFilter == "text1"
                  ? "Immerse yourself in opulence and celebrate your special moments in our exquisite banquet hall. With its elegant ambiance and flawless service, create unforgettable memories at Chiku Orchard."
                  : ""}
                {ourAmeFilter == "text2"
                  ? "Step into a paradise of greenery and tranquillity. Host outdoor events amidst lush gardens at Chiku Orchard. Embrace nature's embrace while we take care of every detail for a magical experience."
                  : ""}
                {ourAmeFilter == "text3"
                  ? "Create cherished family moments in our spacious and well-appointed family rooms. Ideal for bonding and making memories, Chiku Orchard ensures a delightful stay for you and your loved ones."
                  : ""}
                {ourAmeFilter == "text4"
                  ? "Indulge in luxury and comfort in our thoughtfully designed suites. With modern amenities and serene views, enjoy a lavish stay at Chiku Orchard. Experience a perfect blend of elegance and relaxation."
                  : ""}
                {ourAmeFilter == "text5"
                  ? "Begin your journey of love in our romantic honeymoon rooms. Experience intimate bliss with luxurious amenities and breathe taking surroundings. Let Chiku Orchard be the backdrop of your dream honeymoon."
                  : ""}
              </p>
            </div>
          </div>


          <div class="col-lg-6 col-md-4 col-12 p-2">
            <div class="ourAmeniMain">
              <div
                class="ourAmeni"
                style={{ background: `url(${require("./images/hotel1.jpg")})`, backgroundSize: '100% 100%' }}
              >
                <div>
                  <p class="  fs-1 text-white waveTextHide">
                    <div style={{ position: "relative" }}>
                      Banquet Hall
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </p>

                  <button
                    class="btn mt-5 m-auto border rounded-5 p-3 ourAmeBtn"
                    onClick={() => setOurAmeFilter("text1")}
                  >
                    <RightSvgWhite />
                  </button>
                </div>
              </div>
              <div class="ourAmeniT">
                <div>
                  <p
                    class="  fs-1 text-white m-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div style={{ position: "relative" }}>
                      Banquet Hall
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </p>
                  <Link
                    activeClass="active"
                    to="ourAmeText"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      class="btn mt-5 m-auto border rounded-5 p-3 ourBtnarowMain  "
                      onClick={() => setOurAmeFilter("text1")}
                    >
                      <span class="ourBtnarow">
                        <RightSvgWhite />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-4 col-12 p-2">
            <div class="ourAmeniMain">
              <div
                class="ourAmeni"
                style={{ background: `url(${require("./images/hotel2.jpg")})`, backgroundSize: '100% 100%' }}
              >
                <div>
                  <p class="  fs-1 text-white waveTextHide">
                    <div style={{ position: "relative" }}>
                      Lawn Garden
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </p>
                  <button
                    class="btn mt-5 m-auto border rounded-5 p-3 ourAmeBtn"
                    onClick={() => setOurAmeFilter("text2")}
                  >
                    <RightSvgWhite />
                  </button>
                </div>
              </div>
              <div class="ourAmeniT">
                <div>
                  <p
                    class="  fs-1 text-white m-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div style={{ position: "relative" }}>
                      Lawn Garden
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </p>
                  <Link
                    activeClass="active"
                    to="ourAmeText"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      class="btn mt-5 m-auto border rounded-5 p-3 ourBtnarowMain  "
                      onClick={() => setOurAmeFilter("text2")}
                    >
                      <span class="ourBtnarow">
                        <RightSvgWhite />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="col-lg-3 col-md-4 col-12 p-2">
            <div class="ourAmeniMain">
              <div
                class="ourAmeni"
                style={{ background: `url(${require("./images/hotel3.jpg")})`, backgroundSize: '100% 100%' }}
              >
                <div>
                  <p class="  fs-1 text-white waveTextHide">
                    <div style={{ position: "relative" }}>
                      Family Room
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </p>
                  <button
                    class="btn mt-5 m-auto border rounded-5 p-3 ourAmeBtn"
                    onClick={() => setOurAmeFilter("text3")}
                  >
                    <RightSvgWhite />
                  </button>
                </div>
              </div>
              <div class="ourAmeniT">
                <div>
                  <p
                    class="  fs-1 text-white m-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div style={{ position: "relative" }}>
                      Family Room
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>

                    <div style={{ position: "relative" }}>
                      coming soon
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </p>
                  <Link
                    activeClass="active"
                    to="ourAmeText"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      class="btn mt-5 m-auto border rounded-5 p-3 ourBtnarowMain"
                      onClick={() => setOurAmeFilter("text3")}
                    >
                      <span class="ourBtnarow">
                        <RightSvgWhite />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}

          <div class="col-lg-3 col-md-4 col-12 p-2">
            <div class="ourAmeniMain">
              <div
                class="ourAmeni"
                style={{ background: `url(${require("./images/groomRoom.jpg")})`, backgroundSize: '100% 100%' }}
              >
                <div>
                  <p class="  fs-1 text-white waveTextHide">
                    <div style={{ position: "relative" }}>
                      Groom Room
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    {/* <div style={{ position: "relative" }}>
                       Room
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div> */}
                  </p>

                  <button
                    class="btn mt-5 m-auto border rounded-5 p-3 ourAmeBtn"
                    onClick={() => setOurAmeFilter("text4")}
                  >
                    <RightSvgWhite />
                  </button>
                </div>
              </div>
              <div class="ourAmeniT">
                <div>
                  <p
                    class="  fs-1 text-white m-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div style={{ position: "relative" }}>
                      Groom Room
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                    {/* <div style={{ position: "relative" }}>
                      Rooms
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div> */}

                    {/* <div style={{ position: "relative" }}>
                      coming soon
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div> */}
                  </p>
                  <Link
                    activeClass="active"
                    to="ourAmeText"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      class="btn m-auto border rounded-5 p-3 ourBtnarowMain  "
                      onClick={() => setOurAmeFilter("text4")}
                    >
                      <span class="ourBtnarow">
                        <RightSvgWhite />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>



          <div class="col-lg-6 col-md-4 col-12 p-2">
            <div class="ourAmeniMain">
              <div
                class="ourAmeni"
                style={{ background: `url(${require("./images/brideRoom.jpg")})`, backgroundSize: '100% 100%' }}
              >
                <div>
                  <p class="  fs-1 text-white waveTextHide">
                    <div style={{ position: "relative" }}>
                      Bride Room
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>
                  </p>
                  <button
                    class="btn mt-5 m-auto border rounded-5 p-3 ourAmeBtn"
                    onClick={() => setOurAmeFilter("text5")}
                  >
                    <RightSvgWhite />
                  </button>
                </div>
              </div>
              <div class="ourAmeniT">
                <div>
                  <p
                    class="  fs-1 text-white m-auto"
                    style={{ width: "fit-content" }}
                  >
                    <div style={{ position: "relative" }}>
                      Bride Room
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div>

                    {/* <div style={{ position: "relative" }}>
                      coming soon
                      <svg
                        style={{ position: "absolute", bottom: "0", left: "0" }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="17"
                        viewBox="0 0 548 18"
                        fill="none"
                      >
                        <path
                          d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                        />
                      </svg>
                    </div> */}
                  </p>
                  <Link
                    activeClass="active"
                    to="ourAmeText"
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textDecoration: "none" }}
                  >
                    <button
                      class="btn mt-5 m-auto border rounded-5 p-3 ourBtnarowMain  "
                      onClick={() => setOurAmeFilter("text5")}
                    >
                      <span class="ourBtnarow">
                        <RightSvgWhite />
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>

      <div
        class="whyUsMainPc"
        ref={whyUsDivPc}
        style={{ overflow: "hidden", height: "100vh", background: "white" }}
      >
        <div class="whyUsMainPcSpace"></div>
        <div
          class="d-flex align-items-center justify-content-center whyUsPc"
          style={{
            background: "#0E2225",
            height: "100vh",
            position: "relative",
            borderRadius: "50px 50px 0 0 ",
          }}
        >
          <p
            class="display-1   playfair whyUsText"
            style={{ color: "#35666D" }}
          >
            WHY US
          </p>
          <div
            class="whyUsCard whyUsCardPc1 text-start p-3"
            style={{ width: "220px", height: "165px" }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "600", color: "#fefcec" }}
            >
              Versatile Event Spaces
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: "300", color: "#fefcec" }}
            >
              Stunning banquet hall, lush garden, perfect for any occasion.
            </p>
          </div>
          <div
            class="whyUsCard whyUsCardPc2 text-start p-3"
            style={{ width: "270px", height: "170px" }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "600", color: "#fefcec" }}
            >
              Enchanting Natural Beauty
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: "300", color: "#fefcec" }}
            >
              Embrace nature's charm with majestic Chiku trees and breathtaking
              landscapes.
            </p>
          </div>
          <div
            class="whyUsCard whyUsCardPc3 text-start p-3"
            style={{ width: "220px", height: "155px" }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "600", color: "#fefcec" }}
            >
              Luxurious Accommodations
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: "300", color: "#fefcec" }}
            >
              Thoughtfully designed suites, family, and honeymoon rooms.
            </p>
          </div>
          <div
            class="whyUsCard whyUsCardPc4 text-start p-3"
            style={{ width: "200px", height: "185px" }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "600", color: "#fefcec" }}
            >
              Unforgettable Experiences:
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: "300", color: "#fefcec" }}
            >
              Crafted for magical ambiance, lasting impressions on guests.
            </p>
          </div>
        </div>
      </div>

      <div
        class="whyUsMainMb"
        ref={whyUsDivMb}
        style={{ overflow: "hidden", height: "100vh" }}
      >
        <div class="whyUsMainMbSpace"></div>

        <div
          class="d-flex align-items-center justify-content-center whyUsMb"
          style={{
            background: "#0E2225",
            height: "100vh",
            position: "relative",
            borderRadius: "50px 50px 0 0 ",
          }}
        >
          <p
            class="display-1   playfair whyUsText"
            style={{ color: "#35666D" }}
          >
            WHY US
          </p>
          <div
            class="whyUsCard whyUsCardMb1 text-start p-3"
            style={{ width: "250px", height: "160px" }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "600", color: "#fefcec" }}
            >
              Versatile Event Spaces
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: "300", color: "#fefcec" }}
            >
              Stunning banquet hall, lush garden, perfect for any occasion.
            </p>
          </div>
          <div
            class="whyUsCard whyUsCardMb2 text-start p-3"
            style={{ width: "300px", height: "170px" }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "600", color: "#fefcec" }}
            >
              Enchanting Natural Beauty
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: "300", color: "#fefcec" }}
            >
              Embrace nature's charm with majestic Chiku trees and breathtaking
              landscapes.
            </p>
          </div>
          <div
            class="whyUsCard whyUsCardMb3 text-start p-3"
            style={{ width: "240px", height: "160px" }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "600", color: "#fefcec" }}
            >
              Luxurious Accommodations
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: "300", color: "#fefcec" }}
            >
              Thoughtfully designed suites, family, and honeymoon rooms.
            </p>
          </div>
          <div
            class="whyUsCard whyUsCardMb4 text-start p-3"
            style={{ width: "240px", height: "160px" }}
          >
            <p
              style={{ fontSize: "20px", fontWeight: "600", color: "#fefcec" }}
            >
              Unforgettable Experiences
            </p>
            <p
              style={{ fontSize: "16px", fontWeight: "300", color: "#fefcec" }}
            >
              Crafted for magical ambiance, lasting impressions on guests.
            </p>
          </div>
        </div>
      </div>

      <div
        style={{
          background: "linear-gradient(#0E2225 50%, white 50%)",
          marginTop: "-10px",
          overflow: "hidden",
        }}
      >
        <br />
        <div
          style={{
            borderRadius: "30px",
            backgroundImage: `url(${require("./images/valuesBg.jpg")})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <br />
          <p
            class="display-1 text-white playfair my-5 titleSize"
            data-aos="zoom-in"
          >
            Our values{" "}
          </p>

          <div class="col-11 m-auto d-flex justify-content-evenly flex-wrap gap-5 ">
            <div
              style={{ position: "relative", width: "225px", height: "250px" }}
              class="valuesMain"
              data-aos="zoom-in"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style={{ width: "100%", height: "100%" }}
              >
                <div>
                  <img
                    src={require("./images/icons.png")}
                    style={{ width: "84px", height: "100px" }}
                    alt=""
                  />
                  <p class="text-white">Serene</p>
                </div>
              </div>
              <div
                style={{ width: "100%", position: "absolute", top: "0" }}
                class="valuesCircle"
              >
                <img
                  src={require("./images/Vector.png")}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
            </div>

            <div
              style={{ position: "relative", width: "225px", height: "250px" }}
              class="valuesMain"
              data-aos="zoom-in"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style={{ width: "100%", height: "100%" }}
              >
                <div>
                  <img
                    src={require("./images/icons.png")}
                    style={{ width: "84px", height: "100px" }}
                    alt=""
                  />
                  <p class="text-white">Luxurious </p>
                </div>
              </div>
              <div
                style={{ width: "100%", position: "absolute", top: "0" }}
                class="valuesCircle"
              >
                <img
                  src={require("./images/Vector.png")}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
            </div>

            <div
              style={{ position: "relative", width: "225px", height: "250px" }}
              class="valuesMain"
              data-aos="zoom-in"
            >
              <div
                class="d-flex align-items-center justify-content-center"
                style={{ width: "100%", height: "100%" }}
              >
                <div>
                  <img
                    src={require("./images/icons.png")}
                    style={{ width: "84px", height: "100px" }}
                    alt=""
                  />
                  <p class="text-white">Immersive </p>
                </div>
              </div>
              <div
                style={{ width: "100%", position: "absolute", top: "0" }}
                class="valuesCircle"
              >
                <img
                  src={require("./images/Vector.png")}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
            </div>
          </div>
          <br />
        </div>
      </div>
    </div>
  );
};

export default Home;
