import React from 'react'

export const RightSvgWhite = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 17 36" fill="none">
        <path d="M4.47773 11.91C1.04689 8.20553 0.352809 3.79152 0.352783 0.824232L3.60756 0.824219C3.60759 3.62372 4.28051 8.6872 7.22902 11.8709C9.08643 13.8764 11.9018 16.3506 16.2351 17.8467V18.2948C10.6547 17.0195 6.91107 14.5374 4.47773 11.91Z" fill="#FEFDF1"/>
        <path d="M4.47773 24.6799C1.04689 28.3843 0.352809 32.7983 0.352783 35.7656L3.60756 35.7656C3.60759 32.9661 4.28051 27.9026 7.22902 24.719C9.08643 22.7134 11.9018 20.2392 16.2351 18.7432V18.295C10.6547 19.5703 6.91107 22.0525 4.47773 24.6799Z" fill="#FEFDF1"/>
    </svg>
  )
}

export const RightSvgBlack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" viewBox="0 0 17 36" fill="none">
        <path d="M4.47773 11.91C1.04689 8.20553 0.352809 3.79152 0.352783 0.824232L3.60756 0.824219C3.60759 3.62372 4.28051 8.6872 7.22902 11.8709C9.08643 13.8764 11.9018 16.3506 16.2351 17.8467V18.2948C10.6547 17.0195 6.91107 14.5374 4.47773 11.91Z" fill="#000000"/>
        <path d="M4.47773 24.6799C1.04689 28.3843 0.352809 32.7983 0.352783 35.7656L3.60756 35.7656C3.60759 32.9661 4.28051 27.9026 7.22902 24.719C9.08643 22.7134 11.9018 20.2392 16.2351 18.7432V18.295C10.6547 19.5703 6.91107 22.0525 4.47773 24.6799Z" fill="#000000"/>
    </svg>
  )
}


 