import React, { useEffect, useRef, useState } from "react";
import { RightSvgBlack, RightSvgWhite } from "../RightSvg";
import AOS from "aos";
import "aos/dist/aos.css";
import "../home.css";
import { useNavigate } from "react-router-dom";
const LoanGarden = () => {

  const [showImg, setShowImg] = useState()
  const [img, setImg] = useState()
  const navigate = useNavigate()

  useEffect(() => {
    if (showImg == 'dining') {
      setImg(require('../images/p4.png'))
    } else if (showImg == 'spa') {
      setImg(require('../images/pentHouse.jpg'))
    } else if (showImg == 'sightView') {
      setImg(require('../images/gardenInteer1.jpg'))
    } else if (showImg == 'poolSide') {
      setImg(require('../images/aboutP5.jpg'))
    } else if (showImg == 'other') {
      setImg(require('../images/p5.png'))
    } else {
      setImg('')
    }
  }, [showImg])


  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const wave = useRef(null);

  useEffect(() => {
    const image = document.querySelector(".waveline");
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          image.classList.add("active");
        } else {
          image.classList.remove("active");
        }
      },
      { rootMargin: "0px", threshold: 0.5 }
    );
    if (wave.current) {
      observer.observe(wave.current);
    }
    return () => {
      if (wave.current) {
        observer.unobserve(wave.current);
      }
    };
  }, [wave]);

  return (
    <div style={{ background: ' linear-gradient(180deg, rgba(254, 251, 228, 0.00) 0%, rgba(254, 251, 227, 0.69) 100%)' }}>
      <div
        class="d-flex justify-content-center align-items-center"
        style={{
          height: "100vh",
          backgroundImage: `url(${require("../images/hotel4.jpg")})`,
          backgroundSize: "cover",
          objectFit: "cover",
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div>
          <p
            class="display-1 playfair  titleSize text-white"
            data-aos="zoom-in"
          >
            Suites Rooms
          </p>
          <p
            class="fs-5 text-white  col-lg-6 col-md-8 col-11 tJustify  m-auto mt-5 "
            data-aos="fade-up"
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          </p>
          {/* <button class="btn buttonBlack my-4" style={{ color: "white" }}>
            <span class="d-flex align-items-center gap-2">
              <span class="fs-5">Book Now</span>{" "}
              <span class="buttonBlackSpan"></span>
              <RightSvgWhite />
            </span>
          </button> */}
          <button
            class="btn buttonBlack my-4"
            style={{ color: "white" }}
            onClick={() => navigate('/contact-us')}
          >
            <span class="d-flex align-items-center gap-2">
              <span class="fs-5">Contact Us</span>{" "}
              <span class="buttonBlackSpan"></span>
              <RightSvgWhite />
            </span>
          </button>
        </div>
      </div>

      <div>
        <div class="col-11 m-auto row">
          <div
            class="col-md-7 "
            style={{ position: "relative", color: "#0C4027" }}
            data-aos="zoom-in"
          >
            <div class="playfair text-start py-3   display-1   d-flex gap-3  flex-wrap">
              <div>Where</div>
              <div>Every</div>
              <div>Moment</div>
              <div>Feels</div>
              <div ref={wave}> Like </div>
              <div>a Dream</div>
            </div>
            <div
              style={{ position: "absolute", top: "0", color: "#0C4027" }}
              class="waveline"
            >
              <div class="playfair text-start py-3   display-1    d-flex  gap-3  flex-wrap">
                <div>Where</div>
                <div>Every</div>
                <div>Moment</div>
                <div>Feels</div>
                <div> Like </div>
                <div style={{ position: "relative", width: "fit-content" }}>
                  a Dream
                  <svg
                    style={{ position: "absolute", bottom: "-10", left: "10" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="18"
                    viewBox="0 0 548 18"
                    fill="none"
                  >
                    <path
                      d="M2 16L24.2681 4.21099C29.2437 1.57685 35.2007 1.57685 40.1763 4.21099L54.4904 11.789C59.466 14.4232 65.4229 14.4232 70.3985 11.789L84.7126 4.21099C89.6882 1.57685 95.6452 1.57685 100.621 4.21099L114.935 11.789C119.91 14.4232 125.867 14.4232 130.843 11.789L145.157 4.21099C150.133 1.57685 156.09 1.57685 161.065 4.21099L175.379 11.789C180.355 14.4232 186.312 14.4232 191.287 11.789L205.601 4.21099C210.577 1.57685 216.534 1.57685 221.51 4.21099L235.824 11.789C240.799 14.4232 246.756 14.4232 251.732 11.789L266.046 4.21099C271.022 1.57685 276.978 1.57685 281.954 4.21099L296.268 11.789C301.244 14.4232 307.201 14.4232 312.176 11.789L326.49 4.21099C331.466 1.57685 337.423 1.57685 342.399 4.21099L356.713 11.789C361.688 14.4232 367.645 14.4232 372.621 11.789L386.935 4.21099C391.91 1.57685 397.867 1.57685 402.843 4.21099L417.157 11.789C422.133 14.4232 428.09 14.4232 433.065 11.789L447.379 4.21099C452.355 1.57685 458.312 1.57685 463.287 4.21099L477.601 11.789C482.577 14.4232 488.534 14.4232 493.51 11.789L507.824 4.21099C512.799 1.57685 518.756 1.57685 523.732 4.21099L546 16"
                      stroke="#0C4027"
                      stroke-width="3"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-5">
            <div class="col-8 m-auto" data-aos="fade-down">
              <img
                src={require("../images/p5.png")}
                style={{ width: "100%", position: 'relative', top: '100px' }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class='col-11 m-auto row flex-column-reverse flex-md-row gap-md-0 gap-5 '>
        <div class='col-md-6 d-flex align-items-center'>
          <div class='col-11 m-md-0 m-auto text-start '>
            <p class='fs-5 tJustify' data-aos="fade-up">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
            <div data-aos="fade-up " class='mt-4'>
              <button class='btn button'   >
                <span class='d-flex align-items-center gap-2'>
                  <span class='fs-5'>Book Now</span> <span class='buttonSpan'><RightSvgBlack /></span>
                </span>
              </button>
            </div>
          </div>
        </div>
        <div class='col-md-6'>
          <div class='col-12' >
            <img src={require('../images/detail3.jpg')} style={{ width: '100%' }} alt="" />
          </div>
        </div>

      </div>

      <div class='col-11 m-auto my-5' style={{ height: '70vh', borderRadius: '50px' }} data-aos="zoom-in">
        <img src={require('../images/details2.jpg')} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50px' }} alt="" />
      </div>

      <div class='mt-5'>
        <p class="display-1 playfair  titleSize " data-aos="zoom-in" style={{ color: '#985819' }}>
          Experience
        </p>
        <div class='' style={{ backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat', backgroundSize: '200px', backgroundPosition: '25%' }}>
          <div style={{ width: 'fit-content', margin: 'auto' }}>
            <p class='display-5 my-4 playfair fw-semibold' style={showImg == 'dining' ? { cursor: 'pointer', color: '#0C4027' } : { color: '#CDC9AD' }} onMouseEnter={() => setShowImg('dining')} onMouseLeave={() => setShowImg('')}>Dining</p>
            <p class='display-5 my-4 playfair fw-semibold' style={showImg == 'spa' ? { cursor: 'pointer', color: '#0C4027' } : { color: '#CDC9AD' }} onMouseEnter={() => setShowImg('spa')} onMouseLeave={() => setShowImg('')}>Spa</p>
            <p class='display-5 my-4 playfair fw-semibold' style={showImg == 'sightView' ? { cursor: 'pointer', color: '#0C4027' } : { color: '#CDC9AD' }} onMouseEnter={() => setShowImg('sightView')} onMouseLeave={() => setShowImg('')}>Sight View</p>
            <p class='display-5 my-4 playfair fw-semibold' style={showImg == 'poolSide' ? { cursor: 'pointer', color: '#0C4027' } : { color: '#CDC9AD' }} onMouseEnter={() => setShowImg('poolSide')} onMouseLeave={() => setShowImg('')}>Pool Side</p>
            <p class='display-5 my-4 playfair fw-semibold' style={showImg == 'other' ? { cursor: 'pointer', color: '#0C4027' } : { color: '#CDC9AD' }} onMouseEnter={() => setShowImg('other')} onMouseLeave={() => setShowImg('')}>Other Activities</p>
          </div>
        </div>
        <br /><br />

      </div>
      <div
        style={{
          borderRadius: "30px",
          backgroundImage: `url(${require("../images/valuesBg.jpg")})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <br />
        <p class="display-1 text-white playfair my-5 titleSize" data-aos="zoom-in"  >Suites Rooms </p>

        <div class="col-11 m-auto d-flex justify-content-evenly flex-wrap gap-5 ">
          <div
            style={{ position: "relative", width: "225px", height: "250px" }}
            class="valuesMain"
            data-aos="zoom-in"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100%" }}
            >
              <div>
                <img
                  src={require("../images/icons.png")}
                  style={{ width: "84px", height: "100px" }}
                  alt=""
                />
                <p class="text-white">Loyalty</p>
              </div>
            </div>
            <div
              style={{ width: "100%", position: "absolute", top: "0" }}
              class="valuesCircle"
            >
              <img
                src={require("../images/Vector.png")}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </div>
          </div>

          <div
            style={{ position: "relative", width: "225px", height: "250px" }}
            class="valuesMain"
            data-aos="zoom-in"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100%" }}
            >
              <div>
                <img
                  src={require("../images/icons.png")}
                  style={{ width: "84px", height: "100px" }}
                  alt=""
                />

                <p class="text-white">Quality</p>
              </div>
            </div>
            <div
              style={{ width: "100%", position: "absolute", top: "0" }}
              class="valuesCircle"
            >
              <img
                src={require("../images/Vector.png")}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </div>
          </div>

          <div
            style={{ position: "relative", width: "225px", height: "250px" }}
            class="valuesMain"
            data-aos="zoom-in"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              style={{ width: "100%", height: "100%" }}
            >
              <div>
                <img
                  src={require("../images/icons.png")}
                  style={{ width: "84px", height: "100px" }}
                  alt=""
                />
                <p class="text-white">Integrity</p>
              </div>
            </div>
            <div
              style={{ width: "100%", position: "absolute", top: "0" }}
              class="valuesCircle"
            >
              <img
                src={require("../images/Vector.png")}
                style={{ width: "100%", height: "100%" }}
                alt=""
              />
            </div>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default LoanGarden;
