import React, { useEffect, useState } from 'react'
import './style.css'
import { CgMenuRight } from 'react-icons/cg';
import { useLocation, useNavigate } from 'react-router-dom';
import Home from './Home';


const Navbar = () => {
  const [isActive, setIsActive] = useState(false);
  const [pageName,setPageName] = useState()

  const location = useLocation();


  const namePge = location?.pathname

  useEffect(()=>{
    if(namePge == '/'){
      setPageName('Home')
    }else if(namePge == '/about-us'){
      setPageName('About Us')
    }else if(namePge == '/our-amenities'){
      setPageName('Our Amenities')
    }else if(namePge == '/contact-us'){
      setPageName('Contact Us')
    }else if(namePge == '/lawn-garden'){
      setPageName('Lawn Garden')
    }else if(namePge == '/banquet-hall'){
      setPageName('Banquet Hall')
    }else if(namePge == '/family-rooms'){
      setPageName('Family Rooms')
    }else if(namePge == '/suites-rooms'){
      setPageName('Suites Rooms')
    }else if(namePge == '/honeymoon-rooms'){
      setPageName('Honeymoon Rooms')
    }

  },[namePge])

 

  useEffect(()=>{
    const nav = document.querySelector('.navbarMain');
    let lastScrollY = window.scrollY; 
    window.addEventListener('scroll',()=>{
      if(lastScrollY < window.scrollY){
        nav.classList.add('navbarMain--hidden');
      }else{
        nav.classList.remove('navbarMain--hidden');
      }  
      lastScrollY =window.scrollY 
    })  
})

  useEffect(()=>{
    const nav = document.querySelector('.menus')
    nav.style.transition ="0.5s"
    nav.style.clipPath = 'circle(0% at 100% 0%)'
    if(isActive){
    nav.style.transition ="1s" 
    nav.style.clipPath = 'circle(150% at 100% 0%)' 
    } 
  },[isActive])

  const navigate = useNavigate()

  const movePage = (path)=>{
    setIsActive(false)
    navigate(path)
  }
  return (
    <div>

    <div class='navbarMain' style={{width:'100%',overflowX:'hidden'}}>
        <div class=' col-11 m-auto d-flex align-items-center  justify-content-between'>
          <div>
            <img src={require('./images/logo.png')} style={{height:'100px',cursor:'pointer'}} alt="" onClick={()=>movePage('/')} />
          </div>
          <div>
            {/* <CgMenuRight class='fs-4  ' style={{color:'#E3D5BA',cursor:'pointer',fontWeight:'800'}}/> */}
            <div class={isActive ? 'hamburger is-active' : 'hamburger'} id="hamburger" onClick={()=> setIsActive(!isActive)} >
              <span class="line"></span>
              <span class="line"></span>
              <span class="line"></span>
            </div> 
          </div>
        </div> 
    </div>
        <div class='menus d-flex justify-content-center align-items-center' style={{width:'100%',height:'100vh',position:'fixed',top:'0',right:'0',backgroundImage: `url(${require('./images/menu.jpg')})`,backgroundRepeat:'no-repeat',backgroundSize:'100% 100%' , zIndex: '1',overflowX:'hidden'}}>
          <div style={{position:'relative',width:'100%',height:'100%'}}>
          <div class='d-flex align-items-center justify-content-center' style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',zIndex:'-1'}}> 
                <div>
                    <p class='titleSize playfair' style={{color:'#366241'}} >{pageName}</p>
                </div>
            </div>
                <div class='d-grid justify-content-center  align-items-center' style={{height:'100%'}}>
                  <div class='d-grid gap-3'> 
                    <p class='fs-1 playfair fw-semibold' style={{color:"#D7B56C",cursor:'pointer'}} onClick={()=>movePage('/')}>Home</p>
                    <p class='fs-1 playfair fw-semibold' style={{color:"#D7B56C",cursor:'pointer'}} onClick={()=>movePage('/about-us')}>About Us</p>
                    {/* <p class='fs-1 playfair fw-semibold' style={{color:"#D7B56C",cursor:'pointer'}} onClick={()=>movePage('/our-amenities')}>Our Amenities</p> */}
                    <p class='fs-1 playfair fw-semibold' style={{color:"#D7B56C",cursor:'pointer'}} onClick={()=>movePage('/lawn-garden')}>Lawn Garden</p>
                    <p class='fs-1 playfair fw-semibold' style={{color:"#D7B56C",cursor:'pointer'}} onClick={()=>movePage('/banquet-hall')}>Banquet Hall</p>
                    <p class='fs-1 playfair fw-semibold' style={{color:"#D7B56C",cursor:'pointer'}} onClick={()=>movePage('/contact-us')}>Contact Us</p> 
                  </div> 
                </div> 

          </div>
        </div>
    </div>
  )
}

export default Navbar