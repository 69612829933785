import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import LineSvg from './components/LineSvg';
import FramMotion from './components/FramMotion';
import { Route, Routes } from 'react-router-dom';
import AboutUs from './components/AboutUs';
import OurAmenities from './components/OurAmenities';
import ScrollToTop from './components/ScrollToTop';
import Form from './components/Form';
import { useState } from 'react';
import ContactUs from './components/ContactUs';
 
import LoanGarden from './components/DetailPage/LoanGarden';
import Rooms from './components/DetailPage/Rooms';
import PentHouse from './components/DetailPage/PentHouse';
import BanquetHall from './components/DetailPage/BanquetHall';
import Suites from './components/DetailPage/Suites';
 
 

function App() {
  const [activForm,setActivForm]= useState(false)

  return (
    <div className="App">
    <div class={activForm ? 'activeContactForm':'contactForm'} style={{position:'fixed',top:'0',left:'0',zIndex:'1',width:'100%'}}>
      <Form form={setActivForm}/>
    </div> 
    <ScrollToTop/>
      <Navbar/> 
        <Routes>
          <Route path='/' element={ <Home/>  }/> 
          <Route path='/about-us' element={ <AboutUs/> }/> 
          <Route path='/our-amenities' element={ <OurAmenities/> }/> 
          <Route path='/contact-us' element={ <ContactUs/> }/> 
          <Route path='/lawn-garden' element={ <LoanGarden/> }/>   
          {/* <Route path='/family-rooms' element={ <Rooms/> }/>   */}
          {/* <Route path='/honeymoon-rooms' element={ <PentHouse/> }/>   */}
          <Route path='/banquet-hall' element={ <BanquetHall/> }/>  
          <Route path='/suites-rooms' element={ <Suites/> }/>  
        





        </Routes>
      <Footer form={setActivForm}/>
    </div>
  );
}

export default App;
